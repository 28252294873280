import * as utils from './util';
import versions from './site-version-map';

const {
    addScriptToDocument,
    buildScriptUrl,
    getCookieByName,
    getLocalStorage,
} = utils;

(() => {
    const EVENT_SOURCE_DEMO = 'demo';
    const trackerParamName = '_hh_campaign';
    const expirationPrefix = '_expiration_key_prefix';
    const currentTimestamp = Math.floor(((new Date()).getTime()) / 1000);
    const popupLastTimeParamName = '_hh_popup_last_time';

    const id = window.__haftahave.id || null;
    if (!id) {
        console.error(`Haftahave features disabled, invalid id: "${id}"`);
        return;
    }

    window.__haftahave.utils = utils;
    window.__haftahave.__hh_debug = Boolean(getCookieByName('__hh_debug'));
    window.__haftahave.currentTimestamp = currentTimestamp;
    window.__haftahave.expirationPrefix = expirationPrefix;
    window.__haftahave.popupLastTimeParamName = popupLastTimeParamName;
    window.__haftahave.trackerParamName = trackerParamName;

    if (window.__haftahave.isLoaded === 1) {
        window.__haftahave.__hh_debug && console.log('Haftahave scripts embedded more than once, ignoring duplicates');
        return;
    }
    window.__haftahave.isLoaded = 1;

    const { location } = document;
    const bucketUrl = process.env.HH_AWS_S3_BUCKET_TRACKING;

    const version = versions[id] || 1;

    const trackerScript = buildScriptUrl(bucketUrl, id, 'tracker', version);
    const abScript = buildScriptUrl(bucketUrl, id, 'abandoned-checkout', version);
    const popupScript = buildScriptUrl(bucketUrl, id, 'popup', version);

    window.onerror = (message, source) => {
        if (window.__haftahave.__hh_debug) {
            return false;
        }
        return [abScript, popupScript, trackerScript].includes(source);
    };

    // campaign tracking
    const url = new URL(location.href);
    const trackingHash = url.searchParams.get(trackerParamName) || (() => {
        const localStorage = getLocalStorage();
        if (!localStorage) {
            return getCookieByName(trackerParamName);
        }

        const expireTime = parseInt(localStorage.getItem(expirationPrefix + trackerParamName), 10);
        const timeLeft = expireTime && !Number.isNaN(expireTime) ? expireTime - currentTimestamp : null;
        const isExpired = timeLeft !== null && timeLeft <= 0;
        if (isExpired) {
            localStorage.removeItem(trackerParamName);
            localStorage.removeItem(expirationPrefix + trackerParamName);
            return null;
        }

        return localStorage.getItem(trackerParamName);
    })();
    const isTrackingActive = Boolean(trackingHash && trackingHash !== EVENT_SOURCE_DEMO);
    if (isTrackingActive) {
        addScriptToDocument(trackerScript);
    }

    // popup
    const hasToDisplay = (() => {
        const localStorage = getLocalStorage();
        if (!localStorage) {
            return getCookieByName(popupLastTimeParamName);
        }

        const lastTime = parseInt(localStorage.getItem(popupLastTimeParamName), 10);
        return !lastTime || Number.isNaN(lastTime);
    })();
    if (hasToDisplay) {
        addScriptToDocument(popupScript);
    }

    // abandoned checkout tracking
    addScriptToDocument(abScript);
})();
