/**
 * @param {string} scriptUrl
 * @param {boolean} async
 * @param {anonymous} integrity
 * @param {anonymous} crossorigin
 * @param {Function} onload
 * @returns {HTMLScriptElement}
 */
const addScriptToDocument = (scriptUrl, async = true, integrity = '', crossorigin = 'anonymous', onload = () => {}) => {
    const scriptElement = document.createElement('script');
    if (async) {
        scriptElement.setAttribute('async', 'true');
    }
    scriptElement.type = 'text/javascript';
    scriptElement.src = scriptUrl;
    scriptElement.integrity = integrity;
    scriptElement.crossorigin = crossorigin;
    scriptElement.onload = onload;

    const container = (document.getElementsByTagName('head') || [null])[0]
        || document.getElementsByTagName('script')[0].parentNode;
    container.appendChild(scriptElement);

    return scriptElement;
};

/**
 * @param {string} url
 * @param {string} integrity
 * @param {string} crossorigin
 * @param {Function} onload
 * @returns {HTMLLinkElement}
 */
const addStylesheetToDocument = (url, integrity = '', crossorigin = 'anonymous', onload = () => {}) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    link.crossorigin = crossorigin;
    link.integrity = integrity;
    link.onload = onload;
    // link.type = 'text/css';

    document.head.appendChild(link);

    return link;
};

/**
 * @param {string} s
 * @returns {string}
 */
const decode = (s) => s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);

/**
 * @param {string} key
 * @returns {*}
 */
const getCookieByName = (key) => {
    const cookies = document.cookie ? document.cookie.split('; ') : [];

    const result = {};
    for (let i = 0; i < cookies.length; i += 1) {
        const parts = cookies[i].split('=');
        let cookie = parts.slice(1).join('=');

        if (cookie.charAt(0) === '"') {
            cookie = cookie.slice(1, -1);
        }

        try {
            const name = decode(parts[0]);
            cookie = decode(cookie);

            result[name] = cookie;

            if (key === name) {
                break;
            }
        } catch (e) {
            window.__haftahave.__hh_debug && console.error('Error getCookieByName: ', e);
        }
    }

    return key ? result[key] : result;
};

/**
 * @see js-cookie
 * @param {string} key
 * @param {string|Object} value
 * @param {Object} attributes
 * @returns {string}
 */
const setCookie = (key, value, attributes) => {
    const attributesToSet = {
        path: '/',
        ...attributes,
    };
    if (typeof attributesToSet.expires === 'number') {
        attributesToSet.expires = new Date(new Date() * 1 + attributesToSet.expires * 864e+5);
    }

    // We're using "expires" because "max-age" is not supported by IE
    attributesToSet.expires = attributesToSet.expires ? attributesToSet.expires.toUTCString() : '';

    let valueToSet = value;
    try {
        const result = JSON.stringify(value);
        if (/^[\{\[]/.test(result)) {
            valueToSet = result;
        }
    } catch (e) {} // eslint-disable-line no-empty

    valueToSet = encodeURIComponent(String(value))
        .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

    const keyToSet = encodeURIComponent(String(key))
        .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
        .replace(/[\(\)]/g, escape);

    const stringifiedAttributes = Object.keys(attributesToSet).reduce((acc, attributeName) => {
        if (!attributesToSet[attributeName]) {
            return acc;
        }
        const string = `${acc}; ${attributeName}`;
        if (attributesToSet[attributeName] === true) {
            return string;
        }
        // Considers RFC 6265 section 5.2:
        // ...
        // 3.  If the remaining unparsed-attributes contains a %x3B (";")
        //     character:
        // Consume the characters of the unparsed-attributes up to,
        // not including, the first %x3B (";") character.
        // ...
        return `${string}=${attributesToSet[attributeName].split(';')[0]}`;
    }, '');

    document.cookie = `${keyToSet}=${valueToSet}${stringifiedAttributes}`;
    return document.cookie;
};

/**
 * @returns {localStorage|boolean}
 */
const getLocalStorage = () => ('localStorage' in global && global.localStorage ? global.localStorage : false);

/**
 * @param {string} bucketUrl
 * @param {string} clientId
 * @param {string} script
 * @param {string} version
 * @returns {string}
 */
const buildScriptUrl = (bucketUrl, clientId, script, version) => {
    const ext = process.env.ASSET_EXTENSION;
    return `${bucketUrl}/${clientId}/${version}/${script}/${script}${ext}`;
};

/**
 * @param {string} content
 * @param {HTMLElement} container
 * @param {string} containerId
 * @returns {HTMLElement} element
 */
const addHtmlElement = (content, container = document.body, containerId = 'hh_container') => {
    const element = document.createElement('div');
    element.setAttribute('id', containerId);
    element.innerHTML = content;
    container.appendChild(element);
    return element;
};

/**
 *
 * @param {string} content
 * @param {HTMLElement} container
 * @returns {HTMLElement} element
 */
const addStyleElement = (content, container = document.body) => {
    const element = document.createElement('style');
    if ('textContent' in element) {
        element.textContent = content;
    } else {
        element.innerText = content;
    }
    container.appendChild(element);
    return element;
};

/**
 *
 * @param {string} content
 * @param {string} type
 * @returns {HTMLElement}
 * @throws
 */
const addContent = (content, type) => {
    if (type === 'css') {
        return addStyleElement(content);
    }

    if (type === 'html') {
        return addHtmlElement(content);
    }

    throw new Error(`Unknown type "${type}"`);
};

export {
    addContent,
    addHtmlElement,
    addScriptToDocument,
    addStylesheetToDocument,
    buildScriptUrl,
    getCookieByName,
    getLocalStorage,
    setCookie,
};
